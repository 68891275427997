import React from "react";
import {
  Badge,
  Button,
  Card,
  Image,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import DocIcon from "../assets/images/doc.svg";

const Visa = ({
  image,
  title,
  description,
  badge,
  requirements,
  specificPeople,
  phone = "962788158833",
}) => {
  return (
    <Card className="mb-4 position-relative">
      {specificPeople && (
        <Badge
          pill
          bg="danger"
          className="position-absolute fs-6 border"
          style={{ top: "8px", left: "8px" }}
        >
          للمواطنين {specificPeople} فقط
        </Badge>
      )}
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>
          <div className="d-flex gap-2 align-items-center fw-bold">
            {title}
            <Image src={badge} alt="" width={20} height={20} loading="lazy" />
          </div>
        </Card.Title>
        <Card.Text className="truncate-ellipsis">{description}</Card.Text>
        <div className="d-flex gap-2 align-items-center justify-content-between">
          <a
            href={`https://api.whatsapp.com/send/?phone=${phone}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="primary">إحجز الآن</Button>
          </a>
          <OverlayTrigger
            trigger="click"
            key="top"
            placement="top"
            overlay={
              <Popover id="`popover-positioned-top">
                <Popover.Header as="h3">
                  الأوراق المطلوبة لفيزا {title}
                </Popover.Header>
                <Popover.Body>
                  {requirements?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              variant="none"
              className="d-flex gap-2 align-items-center justify-content-between"
            >
              الأوراق المطلوبة
              <Image src={DocIcon} alt="" width={20} height={20} />
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Visa;
