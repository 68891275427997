import React from "react";
import { Container } from "react-bootstrap";

const TermsOfService = () => {
  return (
    <Container>
      <h1>شروط الاستخدام</h1>
      <p>
        مرحباً بكم في موقع "السهم السريع". باستخدامكم لهذا الموقع، فإنكم توافقون
        على الالتزام بشروط الاستخدام التالية. يرجى قراءة هذه الشروط بعناية قبل
        استخدام الموقع.
      </p>

      <h2>استخدام الموقع</h2>
      <p>
        يُمنح لك الإذن لاستخدام هذا الموقع فقط للأغراض القانونية وللاستفادة من
        الخدمات المقدمة فيه. يُمنع استخدام الموقع لأي غرض غير قانوني أو محظور
        بموجب هذه الشروط.
      </p>

      <h2>الملكية الفكرية</h2>
      <p>
        جميع المحتويات الموجودة على هذا الموقع، بما في ذلك النصوص، الصور،
        والشعارات، هي ملكية خاصة لـ "السهم السريع" ومحمية بموجب قوانين حقوق
        الملكية الفكرية. يُمنع إعادة إنتاج، توزيع، أو تعديل أي من محتويات الموقع
        بدون إذن خطي مسبق منا.
      </p>

      <h2>الخصوصية</h2>
      <p>
        استخدامك لهذا الموقع يخضع لسياسة الخصوصية الخاصة بنا، والتي تُوضح كيفية
        جمع، استخدام، ومشاركة معلوماتك الشخصية. باستخدامك لهذا الموقع، فإنك
        توافق على جمع واستخدام المعلومات كما هو موضح في سياسة الخصوصية.
      </p>

      <h2>التعديلات على الشروط</h2>
      <p>
        نحتفظ بالحق في تعديل هذه الشروط في أي وقت. سيتم نشر أي تعديلات على هذه
        الصفحة، ويُعتبر استمرارك في استخدام الموقع بعد نشر التعديلات بمثابة قبول
        منك للشروط المعدلة.
      </p>

      <h2>إخلاء المسؤولية</h2>
      <p>
        نحن نسعى لضمان دقة واكتمال المعلومات المقدمة على هذا الموقع، ولكن لا
        نضمن خلو الموقع من الأخطاء أو عدم انقطاعه. نحتفظ بالحق في تحديث أو تعديل
        محتويات الموقع في أي وقت دون إشعار مسبق.
      </p>

      <h2>القانون الواجب التطبيق</h2>
      <p>
        تخضع هذه الشروط وتُفسر وفقاً لقوانين البلد الذي يقع فيه مقر "السهم
        السريع". أي نزاع ينشأ عن أو يتعلق باستخدام هذا الموقع سيخضع للاختصاص
        القضائي الحصري لمحاكم هذا البلد.
      </p>

      <h2>تواصل معنا</h2>
      <p>
        إذا كان لديك أي استفسارات أو مخاوف بشأن شروط الاستخدام هذه، يرجى التواصل
        معنا عبر وسائل الاتصال المتاحة على موقعنا الإلكتروني.
      </p>

      <p>شكراً لاستخدامكم "السهم السريع"!</p>
    </Container>
  );
};

export default TermsOfService;
