import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>سياسة الخصوصية</h1>
      <p>
        تُقدّر "السهم السريع" خصوصية عملائها وتلتزم بحماية البيانات الشخصية التي
        تُقدم لنا عند استخدام خدماتنا. تهدف سياسة الخصوصية هذه إلى شرح كيفية
        جمع، استخدام، ومشاركة معلوماتك الشخصية.
      </p>

      <h2>جمع المعلومات</h2>
      <p>
        نقوم بجمع المعلومات الشخصية التي تُقدمها لنا بشكل مباشر عند استخدام
        خدماتنا. تشمل هذه المعلومات، على سبيل المثال لا الحصر، الاسم، عنوان
        البريد الإلكتروني، رقم الهاتف، والمستندات اللازمة لإجراءات التأشيرة.
      </p>

      <h2>استخدام المعلومات</h2>
      <p>
        نستخدم المعلومات التي نجمعها لتقديم خدماتنا بشكل فعال، بما في ذلك معالجة
        طلبات التأشيرة، التواصل معك، وتحسين خدماتنا. قد نستخدم معلوماتك أيضاً
        لأغراض تسويقية، بعد الحصول على موافقتك المسبقة.
      </p>

      <h2>مشاركة المعلومات</h2>
      <p>
        لن نقوم بمشاركة معلوماتك الشخصية مع أطراف ثالثة إلا في الحالات الضرورية
        لتقديم خدماتنا، مثل مشاركة المعلومات مع السفارات والقنصليات. سنحرص
        دائماً على أن تكون هذه الأطراف ملتزمة بحماية خصوصيتك.
      </p>

      <h2>حماية المعلومات</h2>
      <p>
        نتخذ جميع التدابير اللازمة لحماية معلوماتك الشخصية من الوصول غير المصرح
        به، أو التعديل، أو الكشف، أو الإتلاف. نستخدم تقنيات وإجراءات أمنية
        متقدمة لضمان حماية بياناتك.
      </p>

      <h2>حقوقك</h2>
      <p>
        لديك الحق في الوصول إلى معلوماتك الشخصية التي نحتفظ بها، وطلب تصحيحها أو
        حذفها، والاعتراض على معالجتها في بعض الحالات. يمكنك التواصل معنا لممارسة
        هذه الحقوق.
      </p>

      <h2>التغييرات على سياسة الخصوصية</h2>
      <p>
        قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإعلامك بأي تغييرات
        جوهرية من خلال نشر السياسة المحدثة على موقعنا الإلكتروني.
      </p>

      <h2>تواصل معنا</h2>
      <p>
        إذا كان لديك أي استفسارات أو مخاوف بشأن سياسة الخصوصية هذه، يرجى التواصل
        معنا عبر وسائل الاتصال المتاحة على موقعنا الإلكتروني.
      </p>

      <p>شكراً لثقتكم بـ "السهم السريع"!</p>
    </Container>
  );
};

export default PrivacyPolicy;
