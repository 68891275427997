import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Visa from "./Visa";

// Images
import US from "../assets/images/usa.webp";
import UScr from "../assets/images/usa-cr.webp";

import Canada from "../assets/images/Canada.webp";
import CanadaCr from "../assets/images/canada-cr.webp";

import Albania from "../assets/images/albania.webp";
import AlbaniaCr from "../assets/images/albania-cr.webp";

import Armenia from "../assets/images/armenia.webp";
import ArmeniaCr from "../assets/images/armenia-cr.webp";

import Europe from "../assets/images/europe.webp";
import EuropeCr from "../assets/images/europe-cr.webp";

import UK from "../assets/images/united-kingdom.webp";
import UKCr from "../assets/images/uk-cr.webp";

import Turkey from "../assets/images/turkey.webp";
import TurkeyCr from "../assets/images/turkey-cr.webp";

import Montenegro from "../assets/images/montenegro.webp";
import MontenegroCr from "../assets/images/montenegro-cr.webp";

import Gorgia from "../assets/images/georgia.webp";
import GorgiaCr from "../assets/images/georgia-cr.webp";

import NBE from "../assets/images/NBE_logo.png";

const VisasList = () => {
  return (
    <Container className="mt-4">
      <div className="d-flex align-items-center justify-content-between flex-column align-items-center flex-md-row">
        <h1 className="fs-1">حدد وجهتك</h1>
        <div className="d-flex align-items-center gap-2">
          <p className="mt-2">
            متوفر الدفع عن طريق محفظة فون كاش للمواطنين المصريين
          </p>
          <Image src={NBE} alt="البنك الاهلي المصري" width={128} />
        </div>
      </div>
      <Row>
        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={US}
            title={"الولايات المتحدة الأمريكية"}
            badge={UScr}
            description={
              "استمتع برحلة إلى الولايات المتحدة الأمريكية، نقدم خدمات شاملة لإصدار التأشيرة. سنساعدك في جميع خطوات العملية لضمان حصولك على تأشيرتك بكل سهولة "
            }
            requirements={[
              "جواز سفر ساري المفعول لأكثر من ستة اشهر.",
              "هوية شخصية) من الأمام/الخلف) ",
              "صورتين شخصية بحجم 3.5*4.5",
              "اثبات عمل لأصحاب الشركات ) رخصة مهن وسجل تجاري). ",
              "اثبات عمل للموظفين كتاب عمل موجه الى السفارة في عمان موقع ومختوم من الشركة + السجل التجاري ورخصة المهن للشركة التي يعمل بها.",
              "كشف حساب لمدة اخر ستة اشهر بالإنجليزي موقع ومختوم من البنك.",
              "دفتر عائلة للمتزوج والأعزب .",
              "الإقامات/تصاريح العمل ) ان وجدت)",
              "فيز الشنغن السابقة ) ان وجدت ).",
              "سندات ملكية ان وجدت) قواشين واراضي ).",
            ]}
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Canada}
            title={"كندا"}
            badge={CanadaCr}
            description={
              "استمتع برحلة إلى كندا مع تأشيرة صادرة منا . نقدم لك الدعم الكامل لإتمام إجراءات التأشيرة بشكل سريع وفعال، حتى تتمكن من استكشاف جمال كندا الخلاب."
            }
            requirements={[
              "جواز سفر ساري المفعول لأكثر من ستة اشهر.",
              "هوية شخصية) من الأمام/الخلف) ",
              "صورتين شخصية بحجم 3.5*4.5",
              "اثبات عمل لأصحاب الشركات ) رخصة مهن وسجل تجاري). ",
              "اثبات عمل للموظفين كتاب عمل موجه الى السفارة في عمان موقع ومختوم من الشركة + السجل التجاري ورخصة المهن للشركة التي يعمل بها.",
              "كشف حساب لمدة اخر ستة اشهر بالإنجليزي موقع ومختوم من البنك.",
              "دفتر عائلة للمتزوج والأعزب .",
              "الإقامات/تصاريح العمل ) ان وجدت)",
              "فيز الشنغن السابقة ) ان وجدت ).",
              "سندات ملكية ان وجدت) قواشين واراضي ).",
            ]}
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={UK}
            title={"بريطانيا"}
            badge={UKCr}
            description={
              "خطط لرحلتك إلى المملكة المتحدة بثقة مع  السهم السريع . نحن هنا لنساعدك في جميع مراحل التقديم للحصول على تأشيرة زيارة أو عمل للمملكة المتحدة بسرعة وسهولة."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Europe}
            title={"أوروبا"}
            badge={EuropeCr}
            description={
              "حقق حلمك بزيارة أوروبا مع خدمات التأشيرة من السهم السريع. نقدم لك المساعدة في الحصول على تأشيرة شنغن التي تتيح لك السفر إلى معظم دول الاتحاد الأوروبي بسهولة."
            }
            requirements={[
              "جواز سفر ساري المفعول الكثر من ستة اشهر.",
              "صورتين شخصية بحجم 3.5*4.5",
              "اثبات عمل ألصحاب الشركات ( رخصة مهن وسجل تجاري ).",
              "اثبات عمل للموظفين كتاب عمل موجه الى السفارة في عمان موقع ومختوم من الشركة + السجل التجاري ورخصة المهن للشركة التي يعمل بها.",
              "كشف حساب لمدة اخر ستة اشهر باالنجليزي موقع ومختوم من البنك.",
              "دفتر عائلة للمتزوج والاعزب .",
              "الاقامات/تصاريح العمل ( ان وجدت )",
              "فيز الشنغن السابقة ( ان وجدت ).",
              "سندات ملكية ان وجدت ( قواشين واراضي ).",
            ]}
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Albania}
            title={"ألبانيا"}
            badge={AlbaniaCr}
            description={
              "تقدم السهم السريع خدمات متكاملة لإصدار تأشيرة ألبانيا. دعنا نساعدك في تحقيق رحلتك إلى هذا البلد الرائع عبر تقديم أفضل الخدمات لك لإصدار التأشيرة."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
            phone="962772906950"
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Armenia}
            title={"أرمينيا"}
            badge={ArmeniaCr}
            description={
              "اكتشف الثقافة الغنية والتاريخ العريق لأرمينيا مع تأشيرة صادرة من السهم السريع. نحن هنا لتسهيل جميع الإجراءات لك للحصول على تأشيرة دخولك إلى أرمينيا."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
            phone="962772906950"
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Turkey}
            title={"تركيا"}
            badge={TurkeyCr}
            description={
              "اكتشف سحر تركيا مع تأشيرة سياحية أو تجارية من السهم السريع. نحن نساعدك في تجهيز جميع المستندات المطلوبة لضمان حصولك على تأشيرتك بكل يسر وسهولة."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
            phone="962772906950"
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Montenegro}
            title={"الجبل الأسود"}
            badge={MontenegroCr}
            description={
              "إذا كنت تتطلع لزيارة الجبل الأسود، نحن في السهم السريع نقدم لك خدمات تأشيرة متميزة لضمان حصولك على تأشيرتك بسرعة وسهولة، مما يتيح لك الاستمشتاع بجمال هذا البلد."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
            phone="962772906950"
          />
        </Col>

        <Col xs={12} md={6} lg={4} xl={3}>
          <Visa
            image={Gorgia}
            title={"جورجيا"}
            badge={GorgiaCr}
            description={
              "تقدم السهم السريع خدمات متكاملة لإصدار تأشيرة جورجيا. دعنا نساعدك في تحقيق رحلتك إلى هذا البلد الرائع عبر تقديم أفضل الخدمات لك لإصدار التأشيرة."
            }
            requirements={["صورة شخصية", "صورة عن جواز السفر"]}
            phone="962772906950"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default VisasList;
