import React from "react";
import { Button, Col, Container, Image, Row, Stack } from "react-bootstrap";
import Logo from "../assets/images/logo-white.svg";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-primary text-white p-4">
      <Container>
        <Row className="gap-4">
          <Col>
            <Image src={Logo} alt="مؤسسة السهم السريع" />
            <p className="text-center mt-1">شركة متخصصة في التأشيرات والسفر</p>
            <Stack
              direction="horizontal"
              gap={3}
              className="border-top pt-2 justify-content-center mt-2"
            >
              <a
                href="https://facebook.com"
                className="text-white"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF size={20} />
              </a>
              <a
                href="https://instagram.com"
                className="text-white"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={20} />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=962788158833"
                className="text-white"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp size={20} />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=962788158833"
                className="text-white"
                target="_blank"
                rel="noreferrer"
              >
                <FaPhone size={20} />
              </a>
            </Stack>
          </Col>
          <Col>
            <p>روابط هامة</p>
            <Stack>
              <Link to="/" onClick={handleClick} className="text-white">
                الصفحة الرئيسية
              </Link>
              <Link to="/visas" onClick={handleClick} className="text-white">
                الخدمات
              </Link>
              <Link to="/about" onClick={handleClick} className="text-white">
                من نحن
              </Link>
              <Link
                to="/privacy-policy"
                onClick={handleClick}
                className="text-white"
              >
                سياسة الخصوصية
              </Link>
              <Link
                to="/terms-of-service"
                onClick={handleClick}
                className="text-white"
              >
                شروط الاستخدام
              </Link>
            </Stack>
          </Col>
          <Col>
            <p>التواصل معنا</p>
            <Stack>
              <a
                href="https://api.whatsapp.com/send/?phone=962772906950"
                className="d-block w-100"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="success" className="mb-2 d-block w-100">
                  للمواطنين المصريين
                  <FaWhatsapp size={20} className="me-2" />
                </Button>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=962788158833"
                className="d-block w-100"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="success" className="d-block w-100">
                  للمواطنين الاردنيين
                  <FaWhatsapp size={20} className="me-2" />
                </Button>
              </a>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
