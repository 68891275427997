import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <Container>
      <h1>من نحن</h1>
      <p>
        مرحباً بكم في "السهم السريع"، شركتكم الرائدة في تقديم خدمات إصدار
        التأشيرات لعدد من الدول حول العالم. نؤمن بأن السفر هو بوابة لاستكشاف
        العالم واكتساب تجارب جديدة، ومن هذا المنطلق نسعى لتسهيل عملية الحصول على
        التأشيرات بجميع أنواعها.
      </p>

      <h2>رؤيتنا</h2>
      <p>
        نطمح لأن نكون الشركة الرائدة في تقديم خدمات التأشيرات من خلال تقديم
        خدمات عالية الجودة وبأسعار تنافسية، مع التركيز على راحة عملائنا ورضاهم
        التام.
      </p>

      <h2>مهمتنا</h2>
      <p>
        نحن ملتزمون بتقديم خدمات تأشيرات سريعة، فعالة، وموثوقة للمسافرين. نعمل
        بجد لضمان أن جميع عملائنا يحصلون على تأشيراتهم بسهولة وبدون أي تعقيدات،
        وذلك عبر تقديم الدعم الكامل في جميع مراحل التقديم.
      </p>

      <h2>قيمنا</h2>
      <ul>
        <li>
          <strong>الاحترافية:</strong> نعمل بفريق متخصص يمتلك الخبرة والمعرفة في
          جميع جوانب إصدار التأشيرات.
        </li>
        <li>
          <strong>النزاهة:</strong> نلتزم بالشفافية والمصداقية في جميع تعاملاتنا
          مع العملاء.
        </li>
        <li>
          <strong>التميز:</strong> نحرص على تقديم خدمات متميزة ترضي توقعات
          عملائنا.
        </li>
        <li>
          <strong>الابتكار:</strong> نبحث باستمرار عن طرق جديدة لتحسين خدماتنا
          وتلبية احتياجات عملائنا المتغيرة.
        </li>
      </ul>

      <h2>خدماتنا</h2>
      <p>
        نقدم في "السهم السريع" خدمات إصدار التأشيرات للعديد من الدول منها
        الولايات المتحدة الأمريكية، كندا، أوروبا، المملكة المتحدة، أرمينيا،
        الجبل الأسود، تركيا، وألبانيا. نوفر لك كل الدعم اللازم من بداية التقديم
        وحتى الحصول على التأشيرة، وذلك لضمان تجربة سفر سلسة ومريحة.
      </p>

      <h2>تواصل معنا</h2>
      <p>
        لأي استفسار أو طلب خدمة، يمكنكم التواصل معنا عبر وسائل الاتصال المتاحة
        على موقعنا الإلكتروني. نحن هنا لخدمتكم وجعل رحلتكم القادمة أكثر سهولة
        ويسر.
      </p>

      <p>شكراً لثقتكم بـ "السهم السريع"!</p>
    </Container>
  );
};

export default About;
