import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import HeroBG from "../assets/images/hero.svg";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <Container className="position-relative hero">
      <div className="d-flex flex-column-reverse flex-md-row align-items-center">
        <div>
          <h1 className="hero-text">
            نحن هنا لنأخذك إلى وجهتك بأسرع الطرق وأفضل الخدمات
          </h1>
          <Button className="px-5 py-1  fs-4 " as={Link} to="/visas">
            احجز الآن
          </Button>
        </div>
        <Image src={HeroBG} alt="Hero" className="w-50" />
      </div>
    </Container>
  );
};

export default Hero;
