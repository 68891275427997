import React from "react";
import VisasList from "../components/VisasList";
import { Container, Image } from "react-bootstrap";
import Bag from "../assets/images/bag.svg";

const Visas = () => {
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center  ">
        <h1 className="hero-text">اختر وجهتك المفضلة </h1>
        <Image src={Bag} alt="Bag" className="d-none d-md-block" />
      </div>
      <VisasList />
    </Container>
  );
};

export default Visas;
