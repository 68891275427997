import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";

import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="md" className="shadow-sm mb-3">
        <Container>
          <Navbar.Brand as={Link} to={"/"}>
            <Image src={Logo} alt="مؤسسة السهم السريع" width={180} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-md`}
            onClick={handleShow}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton className="justify-content-between">
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <Image src={Logo} alt="مؤسسة السهم السريع" width={180} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 fs-5">
                <Nav.Link as={Link} to="/" eventKey="/" onClick={handleClose}>
                  الرئيسية
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/visas"
                  eventKey="visas"
                  onClick={handleClose}
                >
                  خدماتنا
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  eventKey="about"
                  onClick={handleClose}
                >
                  من نحن
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
