import React from "react";
import { Container, Image } from "react-bootstrap";
import Ticket from "../assets/images/ticket.svg";

const Feature = () => {
  return (
    <div>
      <Container>
        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
          <h1>تأشيرتك اليوم صارت اسهل معنا</h1>
          <Image src={Ticket} alt="Ticket" width={280} />
        </div>
      </Container>
    </div>
  );
};

export default Feature;
