import React from "react";

const Copyright = () => {
  return (
    <div className="text-white bg-primary border-top px-2 d-flex align-items-center justify-content-between">
      <span>
        مؤسسة السهم السريع جميع الحقوق محفوظة &copy;{new Date().getFullYear()}
      </span>
      <a
        href="https://api.whatsapp.com/send/?phone=962790407986"
        target="_blank"
        rel="noreferrer"
        className="text-white"
      >
        تصميم وتطوير Abdellatif Alrefahi
      </a>
    </div>
  );
};

export default Copyright;
