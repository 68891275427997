import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
import { ThemeProvider } from "react-bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";

const Layout = () => {
  return (
    <ThemeProvider dir="rtl">
      <Header />
      <Outlet />
      <Footer />
      <Copyright />
    </ThemeProvider>
  );
};

export default Layout;
